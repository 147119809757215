import { graphql } from 'gatsby'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import BlueprintLayout from '../layouts/blueprint'
import TimelineBox from '../modules/about/components/TimelineBox'

const TIMELINE = [
  { date: '2013', location: 'Polytech Montpellier - France', description: 'I start an engineering degree.' },
  { date: '2017', location: 'Night Zookeeper - London', description: 'First web development oriented internship as part of studies.' },
  { date: '2018', location: 'Polytech Montpellier - France', description: 'I graduate as an it and management engineer.' },
  { date: '2018', location: 'Night Zookeeper - London', description: 'First job, full stack developer in London.' },
  { date: '2020', location: 'Night Zookeeper - London', description: 'Promotion as Night Zookeeper\'s CTO.' },
]

const Content = styled.div`
  h1 {
    font-size: 50px;
    text-align: center;
    text-transform: uppercase;
  }

  h1.secondary {
    color: ${props => props.theme.text}66;
  }
`

const Description = styled.div`
  font-size: 1.5em;
  max-width: 802px;
  line-height: 1.2;
  text-align: justify;
  em {
    position: relative;
    padding: 0 5px;
    font-style: normal;
    z-index: 1;
    :before {
      position: absolute;
      left: 0;
      top: calc(0.8em);
      content: '';
      height: .6em;
      width: 100%;
      background-color: ${props => props.theme.accent};
      z-index: -1;
    }
  }
`

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  min-width: 360px;
  width: 100%;
  > * {
    position: relative;
    margin-bottom: 31px;
    :before {
      position: absolute;
      bottom: -61px;
      left: calc(50% - 2px);
      height: 61px;
      width: 0;
      border-right: 3px dashed ${props => props.theme.text}11;
      content: '';
    }
  }
  > :last-child {
    :before {
      display: none;
    }
  }
`

const Sections = styled.div`
  display: flex;
  flex-wrap: wrap;
  > * { margin-right: 40px; }
  @media (max-width: 914px) {
    margin-bottom: 50px;
    text-align: center;
    h1 {
      text-align: center;
    }
    > * {
      margin-right: 0px;
    }
    flex-direction: column;
  }

`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  h2 {
    text-transform: uppercase;
    color: ${props => props.theme.text};
    opacity: .34;
  }
`

const LanguagesSection = styled.div`
  width: 100%;
  h1 {
    text-align: left;
    line-height: 1;
    margin-bottom: 15px;
  }
  p {
    font-size: 34px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.1;
    margin: 0;
  }
  @media (max-width: 914px) {
    margin-bottom: 50px;
    text-align: center;
    h1 {
      text-align: center;
    }
  }
`

const MyStuffSection = styled.div`
  text-transform: uppercase;
  margin-top: 50px;
  .title {
    h2, h3 {
      margin: 0;
      line-height: 1.1;
    }
    h2 {

    }
    h3 {
      color: ${props => props.theme.text}66;
    }
    margin-bottom: 30px;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    max-width: 860px;
  }
  ul {
    margin: 0 20px 30px 0;
    list-style-type: none;
    padding: 0;
    li:first-child {
      font-size: 40px;
      font-weight: 600;
      color: ${props => props.theme.text};
    }
    li {
      font-size: 20px;
      font-weight: 600;
      color: ${props => props.theme.text}66;
    }
  }
`

const AboutPage = () => {
  const { t } = useTranslation()

  return <BlueprintLayout>
    <Content>
      <h1><Trans>About me.</Trans></h1>
      <Sections>
        <Section style={{ minWidth: '335px' }}>
          <Description>
            <p dangerouslySetInnerHTML={{
              __html: t("MY NAME IS BEN, I'VE ALWAYS BEEN ADDICTED TO COMPUTERS. I STARTED PLAYING VIDEOGAMES AS SOON AS IT'S POSSIBLE. MAINLY S-NES WITH MY MOM AND SISTERS. I STARTED CODING WHEN I WAS 14 Y-O, SOME PYTHON, SOME C, AND SOME HTML, CSS AND PHP FOR WEBSITES.")
            }} />
            <p dangerouslySetInnerHTML={{
              __html: t("GROWING UP, I STARTED HATING PHP AND DEVELOPED A BIG CRUSH FOR JAVASCRIPT AND ITS ENTIRE ECOSYSTEM. I KNOW IT'S NOT THE MOST EFFICIENT LANGUAGE BUT IT'S DEFINITELY THE ONE YOU CAN HAVE THE MOST FUN WITH FOR SURE!")
            }} />
          </Description>
          <LanguagesSection>
            <h1 className='secondary'><Trans>I'm fluent in</Trans></h1>
            <p><Trans>French, English, Spanish, Javascript, Dart</Trans></p>
            <p><Trans>And many other...</Trans></p>
          </LanguagesSection>
        </Section>
        <Section style={{ flex: 1, width: '100%', display: 'flex', alignItems: 'center' }}>
          <Timeline>
            {
              TIMELINE.map((e, i) => <TimelineBox
                key={i}
                date={t(e.date)}
                location={t(e.location)}
                description={t(e.description)}
              />)
            }
          </Timeline>
        </Section>
      </Sections>
      <MyStuffSection>
        <div className='title'>
          <h2><Trans>Stuff I use on a daily</Trans></h2>
          <h3><Trans>I could say I'm an expert</Trans></h3>
        </div>
        <div className='items'>
          <ul>
            <li>Frontend</li>
            <li>React</li>
            <li>NextJS</li>
            <li>GatsbyJS</li>
            <li>Flutter</li>
          </ul>
          <ul>
            <li>Backend</li>
            <li>NodeJS</li>
            <li>GraphQL</li>
            <li>NestJS</li>
            <li>Express</li>
            <li>Nginx</li>
            <li>Traefik</li>
          </ul>
          <ul>
            <li>DevOps</li>
            <li>Kubernetes</li>
            <li>Serverless</li>
            <li>Docker</li>
            <li>Unix</li>
          </ul>
          <ul>
            <li>Database</li>
            <li>MongoDB</li>
            <li>PostgreSQL</li>
            <li>Redis</li>
          </ul>
        </div>
      </MyStuffSection>
    </Content>
  </BlueprintLayout>
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AboutPage
