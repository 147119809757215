import React from 'react'
import styled from 'styled-components'

interface IProps {
  date: string
  location: string
  description: string
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .date {
    font-weight: 400;
    font-size: 20px;
    color: ${props => props.theme.accent};
  }
  .location {
    font-weight: 600;
    font-size: 11px;
    padding-bottom: 2px;
  }
  .description {
    background-color: ${props => props.theme.text}11;
    padding: 10px;
    font-size: 14px;
    border-radius: 8px;
  }
`

const TimelineBox = (props: IProps) => {
  return <Wrapper>
    <div className='header'>
      <div className='date'>{props.date}</div>
      <div className='location'>{props.location}</div>
    </div>
    <div className='description'>{props.description}</div>
  </Wrapper>
}

export default TimelineBox
